import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseUrlService } from 'app/app-common/services/base-url/base-url.service';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState, selectRouterState } from '#appState';
import {
  CloseProviderOnboarding,
  CompanyCreate,
  CompanyCreateError,
  CompanyCreateRedirect,
  CompanyCreateSuccess,
  CompanyListLoad,
  CompanyListLoadError,
  CompanyListLoadSuccess,
  CompanySelect,
  CompanySelectReset,
  LoadCompany,
  LoadCompanyError,
  LoadCompanySuccess,
  ShowProviderOnboarding,
} from '../actions/company.actions';
import { catchError, concatMap, filter, flatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ICompanyEntity } from '@ipnote/interface';
import { Observable, of } from 'rxjs';
import { selectStateSelectedCompany, selectStateUserInfo } from '#selectors';
import { MatDialog } from '@angular/material/dialog';
import { ProviderOnboardingPanelComponent } from 'app/app-common/components/onboarding/provider-onboarding-panel/provider-onboarding-panel.component';
import { EnumsService } from 'app/page-modules/desk/services/enums/enums.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ErrorResponseCreate } from '@ipnote/shared';
import { CompaniesService } from '../../page-modules/desk/services/companies/companies.service';
import { isEmpty } from 'lodash';
import { selectStateUser } from '../selectors/user.selectors';
import { UserProfileUpdate, UserRedirectBackUrl } from '../actions/user.actions';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class CompanyEffects {
  onboardingDialogRef;

  LoadCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadCompany),
      mergeMap((props) => {
        return this.companiesService.get(props.companyId).pipe(
          map((company) => LoadCompanySuccess({ company, navigateToCompany: props.navigateToCompany })),
          catchError((err) => of(LoadCompanyError({ err: err }))),
        );
      }),
    ),
  );

  LoadCompanySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoadCompanySuccess),
        map((payload) => {
          return this.store.dispatch(
            CompanySelect({ company: payload.company, thisIsNew: false, reloadPage: payload.navigateToCompany }),
          );
        }),
      ),
    {
      dispatch: false,
    },
  );

  LoadCompanyError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoadCompanyError),
        map((res) => {
          //this.store.dispatch(CompanySelectReset());
          this.store.dispatch(CompanyListLoad());
        }),
      ),
    {
      dispatch: false,
    },
  );

  CompanyListLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyListLoad),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store.select(selectStateUserInfo)))),
      mergeMap(([payload, user]) => {
        return this.companiesService.getAll(1, 1).pipe(
          map((result) => CompanyListLoadSuccess({ result, userId: user.id })),
          catchError((error: ErrorResponseCreate) => of(CompanyListLoadError({ error }))),
        );
      }),
    ),
  );

  CompanyListLoadSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CompanyListLoadSuccess),
        concatMap((action) => of(action).pipe(withLatestFrom(this.store.select(selectStateSelectedCompany)))),
        map(([{ result }, selectedCompany]) => {
          if (result.data.length === 0) {
            /*** Если у пользователя нет компаний, предлагаем создать */
            this.store.dispatch(CompanySelectReset());
            this.router.navigateByUrl('/auth/create-first-company');
          } else {
            if (!selectedCompany || isEmpty(selectedCompany)) {
              this.store.dispatch(CompanySelect({ company: result.data[0], thisIsNew: false, reloadPage: false }));
            } else {
              this.store.dispatch(LoadCompany({ companyId: selectedCompany.id, navigateToCompany: true }));
            }
          }
        }),
      ),
    {
      dispatch: false,
    },
  );

  CompanyCreate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyCreate),
      mergeMap((payload) => {
        return this.baseUrl.getBaseUrl('companies').pipe(
          flatMap((url) => this.http.post<ICompanyEntity>(url, payload.company)),
          map((result) => CompanyCreateSuccess({ result })),
          catchError((error) => of(CompanyCreateError({ error }))),
        );
      }),
    ),
  );

  CompanyCreateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyCreateSuccess),
      map((payload) => {
        return CompanySelect({ company: payload.result, thisIsNew: true, reloadPage: true });
      }),
    ),
  );

  CompanySelect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CompanySelect),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(this.store.select(selectRouterState)),
            withLatestFrom(this.store.select(selectStateUser)),
          ),
        ),
        map(([[action, router], user]) => {
          /** If there is a returnLink, the redirect is always to it */
          if (user.returnLink !== null && action.isAuth && action?.reloadPage) {
            this.store.dispatch(UserRedirectBackUrl({ url: user.returnLink }));
          } else {
            this.store.dispatch(
              UserProfileUpdate({
                payload: {
                  settings: {
                    ...user.user.settings,
                    lastCompanyId: action.company.id,
                  },
                },
              }),
            );
            if (
              (router.urlCurrent.split('/')[1].includes('/public-page') ||
                router.urlCurrent.split('/')[1].includes('/ai')) &&
              action?.reloadPage
            ) {
              window.location.replace(router.urlCurrent);
              return null;
            }
            if (!action?.thisIsNew && action?.reloadPage) {
              if (action.company.isServiceProvider) {
                window.location.replace(`desk/${action?.company.id}/tasks/cards?category=incoming`);
              } else {
                window.location.replace(`desk/${action?.company.id}/tasks/cards?category=outgoing`);
              }
            } else if (action?.thisIsNew) {
              if (user.returnLink) {
                this.store.dispatch(UserRedirectBackUrl({ url: user.returnLink }));
              } else {
                const path = ['/desk', action.company.id, 'tasks', 'cards'];
                this.router
                  .navigate(path, {
                    queryParams: { category: action.company?.isServiceProvider ? 'incoming' : 'outgoing' },
                    replaceUrl: true,
                  })
                  .then();
              }
            }
          }
        }),
      ),
    {
      dispatch: false,
    },
  );

  // ShowOnboardialogModal$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(ShowProviderOnboarding),
  //       map((action) => {
  //         return this.store
  //           .select(subscriptionSelector)
  //           .pipe(
  //             filter((p) => !!p),
  //             withLatestFrom(this.store.select(selectStateSelectedCompany)),
  //             untilDestroyed(this),
  //           )
  //           .subscribe(([subscription, company]) => {
  //             if (this.isShowProviderOnboarding(company, subscription) && !this.onboardingDialogRef) {
  //               this.onboardingDialogRef = this.matDialog.open(ProviderOnboardingPanelComponent, {
  //                 enterAnimationDuration: '0ms',
  //                 data: { panelOpenState: company.onboardingSteps.isFirstRun },
  //                 width: '435px',
  //                 position: { right: '20px', bottom: '16px' },
  //                 panelClass: 'provider-onboarding-panel',
  //                 hasBackdrop: company.onboardingSteps.isFirstRun,
  //                 disableClose: true,
  //               });
  //             }
  //           });
  //       }),
  //     ),
  //   { dispatch: false },
  // );

  CloseOnboardialogModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CloseProviderOnboarding),
        map((payload) => {
          if (this.onboardingDialogRef) {
            this.onboardingDialogRef.close();
            this.onboardingDialogRef = undefined;
          }
        }),
      ),
    { dispatch: false },
  );

  CompanyCreateRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CompanyCreateRedirect),
        map((action) => {
          this.router.navigateByUrl('/auth/create-first-company');
        }),
      ),
    { dispatch: false },
  );

  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result: BreakpointState) => result.matches));
  isHandSet;

  constructor(
    private matDialog: MatDialog,
    private actions$: Actions,
    private router: Router,
    private route: ActivatedRoute,
    private baseUrl: BaseUrlService,
    private http: HttpClient,
    private store: Store<AppState>,
    private enums: EnumsService,
    private companiesService: CompaniesService,
    private breakpointObserver: BreakpointObserver,
  ) {
    this.isHandset$.pipe(untilDestroyed(this)).subscribe((res) => (this.isHandSet = res));
  }

  isShowProviderOnboarding(company, subscription): boolean {
    return (
      !company?.additionalFields.isPrime &&
      company.isServiceProvider &&
      (!company?.onboardingSteps ||
        !company.onboardingSteps?.approved ||
        !company.onboardingSteps?.isCompanyPublicInfoCompleted ||
        !company.onboardingSteps?.areServicesAdded ||
        !company.onboardingSteps?.isOutgoingTaskCreated ||
        !company.onboardingSteps?.isInformationShared)
    );
  }
}
