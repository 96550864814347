import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface DialogData {
  title: string;
  message: any;
  primaryButton: string;
  slaveButton: string;
}

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {
  message;

  constructor(public dialogRef: MatDialogRef<AlertDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    if (Array.isArray(data.message)) {
      this.message = [];
      data.message.forEach(message => {
        if (message?.constraints) {
          this.message = [...this.message, Object.values(message?.constraints)[0]];
        }
      });
    } else {
      this.message = data.message.split('\n').join('<br>');
    }
  }

  ngOnInit() {
  }

  decline() {
    this.dialogRef.close(false);
  }

  accept() {
    this.dialogRef.close(true);
  }
}
