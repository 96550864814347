import * as user from './store/user.state';
import * as company from './store/company.state';
import * as router from './store/reducers/router.reducers';
import * as onboardingProviderPanel from './store/onboarding-privider-panel.state';
import { updateHistoryKey, updateHistoryReducer } from './store/reducers/update-history.reducer';
import { markifyDBsRececyReducer, markifyDBsRecencyKey } from './store/reducers/markify-dbs-recency.reducer';

export interface AppState extends user.StateUser, company.StateCompany {
  router: router.StateRouter;
}

export const reducers = {
  ...onboardingProviderPanel.reducers,
  ...user.reducers,
  ...company.reducers,
  [updateHistoryKey]: updateHistoryReducer,
  [markifyDBsRecencyKey]: markifyDBsRececyReducer,
  router: router.reducer,
};

export const selectRouterState = (state: AppState) => state.router;
