import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if (environment.googleTag) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const googleTag = require('./scripts/google-tag-manager'); // с импортом будет работать не правильно
  const script = window.document.createElement('script');
  script.type = 'text/javascript';
  script.textContent = googleTag;
}

Sentry.init({
  dsn: environment.sentry,
  environment: environment.environment,
  release: environment.version,
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['localhost', environment.baseUrl],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    //new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then((success) => console.log('Bootstrap success'))
    .catch((err) => console.error(err));
});
