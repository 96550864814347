<div @fadeInUp class="mt-4 flex items-center flex-col justify-center">
  <div class="flex flex-col items-center">
    <img class="w-[150px] h-[42px]" src="assets/img/new-ipnote-logo.png" alt="" />
    <div class="text-center my-6">AI powered IP revolution - IP made 5x cheaper and easier</div>
  </div>
  <ng-container *ngIf="step === TwoFactorSteps.SET_UP_TWO_FACTOR">
    <h2 class="text-center font-bold text-lg alert">
      Hey <span class="text-primary-500">{{ userState.user.email }}</span
      >,
    </h2>
    <h2 class="text-center font-bold text-lg mb-4 alert">Set up 2 factor authentication</h2>
    <span class="text-center mb-3">
      Our commitment to safeguarding your IP has led us to upgrade our security measures with two-factor authentication.
      To further protect your account, please provide your phone number
    </span>
    <form #f="ngForm" [formGroup]="phoneForm" class="w-full my-4">
      <app-phone-field-control
        [required]="true"
        [countryCode]="userState?.user?.phone?.countryCode"
        formControlName="phone"
      >
      </app-phone-field-control>
    </form>
    <button
      (click)="send(VerificationCodeType.SMS)"
      [disabled]="phoneForm?.invalid || loading"
      mat-raised-button
      color="primary"
      class="w-full"
    >
      Continue
      <mat-icon *ngIf="loading">
        <mat-spinner color="primary" diameter="20"></mat-spinner>
      </mat-icon>
    </button>
    <div class="mt-2 w-full flex justify-end">
      <span (click)="logOut()" class="cursor-pointer text-primary-500"> Not {{ userState?.user?.name }}?</span>
    </div>
  </ng-container>

  <ng-container *ngIf="step === TwoFactorSteps.VERIFICATION">
    <h2 class="font-bold text-lg mb-4">Verification</h2>

    <ng-container *ngIf="codeType === VerificationCodeType.SMS">
      <span class="text-center mb-3">
        Please enter the code we’ve sent to {{ phoneForm?.controls?.phone?.value?.e164Number }}
      </span>
      <mat-form-field class="w-[80px]" appearance="outline">
        <input
          class="text-center"
          maxlength="4"
          type="email"
          [formControl]="verificationCode"
          matInput
          placeholder="- - - -"
        />
      </mat-form-field>
      <span *ngIf="minutes + seconds > 0; else elseBlock">
        Resend code in {{ minutes | number : '2.0-0' }}:{{ seconds | number : '2.0-0' }}
      </span>
      <ng-template #elseBlock>
        <div class="flex w-full">
          <span
            (click)="send(VerificationCodeType.SMS)"
            class="text-end basis-1/2 mr-2 cursor-pointer text-primary-500"
          >
            Resend code
          </span>
          <span
            (click)="setStep(TwoFactorSteps.SET_UP_TWO_FACTOR)"
            class="basis-1/2 ml-2 cursor-pointer text-primary-500"
          >
            Edit number
          </span>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>

  <ng-containter *ngIf="showSkipTwoFABlock()">
    <div class="skip-2fa-block text-center">
      <div class="mt-14">You can delay this step three times for two days each;</div>
      <div>
        afterward, phone verification is required.
        <button class="skip-2fa-button" (click)="skipTwoFA()">Skip now</button>.
      </div>
    </div>
  </ng-containter>
</div>
