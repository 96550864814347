import { NgModule } from '@angular/core';
import { OnlyNumberDirective } from '../../../../app/page-modules/desk/directives/only-number.directive';
import { StopPreventDirective } from '../../../../app/page-modules/desk/directives/stop-prevent.directive';

@NgModule({
  imports: [],
  declarations: [OnlyNumberDirective, StopPreventDirective],
  exports: [OnlyNumberDirective, StopPreventDirective]
})
export class SharedModule {
}
