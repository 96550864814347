export enum SubscriptionAdditionalFieldsKeys {
  allowedOipCount = 'allowedOipCount',
  allowedCompanyCount = 'allowedCompanyCount',
  feeMinSize = 'feeMinSize',
  feeMinCurrency = 'feeMinCurrency',
  feeSize = 'feeSize',
  incomingFeeSize = 'incomingFeeSize',
  aiPatentSearchesCount = 'aiPatentSearchesCount',
  aiTrademarkSearchesCount = 'aiTrademarkSearchesCount',
  searchTrademarkCount = 'searchTrademarkCount',
  searchTrademarkCountry = 'searchTrademarkCountry',
  searchTrademarkMode = 'searchTrademarkMode',
  reportTrademarkCount = 'reportTrademarkCount',
  isPrime = 'isPrime',
  support = 'support',
  showProviders = 'showProviders',
  searchPriority = 'searchPriority',
  autoOfferDelay = 'autoOfferDelay',
  instantQuotingCountries = 'instantQuotingCountries',
  extraHighlighting = 'extraHighlighting',
  extraHighlightingCountries = 'extraHighlightingCountries',
  extraHighlightingServices = 'extraHighlightingServices',
}
